@tailwind base;
@tailwind components;
@tailwind utilities;


/* CARDS -------------------------------------------------------------------------------------------------------- */

/* card levers -------------------------------------------------------------------------------------------------------- */


.card-shadow{
  @apply
  shadow-md shadow-gray-200
}

.card-design-1{
  @apply
  card-shadow 
  rounded-xl
  max-w-lg
  border-2 border-white 
}



.card-edges{
  @apply 
  rounded-xl
}
.top-edge-rounded{
  @apply 
  rounded-t-xl
}

.card-height{
  @apply 
  h-32 md:h-28
}
.card-height-cf{
  @apply 
  h-24 md:h-24
}
/* card levers end -------------------------------------------------------------------------------------------------------- */

.card-bg-none-padding-none{
  @apply 
  relative
  card-edges
  overflow-hidden
  card-shadow
  max-w-lg
}
.card-bg-white-padding-none{
  @apply 
  card-shadow
  card-edges
  bg-white
  max-w-lg
}

.card-cf-tp{
  @apply 
  card-shadow
  px-4 py-2
  card-edges
  bg-white
  max-w-lg
  hover:bg-gray-50
}
.card-bg-white-padding{
  @apply 
  py-2 px-4 md:py-3
  bg-white
  max-w-lg sm:max-w-sm
  card-edges
  card-shadow
}
.card-bg-white-padding-outline{
  @apply 
  py-2 px-4 md:py-3
  border border-gray-200
  card-edges
  bg-white
  max-w-lg sm:max-w-sm
  card-shadow
}


.card-no-entries{
  @apply 
  bg-white  
  max-w-sm 
  border border-gray-200 
  rounded-xl 
  py-10 px-16 
  md:px-20 
  text-center 
  text-base 
  font-normal 
  text-gray-500
}

.cf-tp-no-entries-title{
  @apply 
  pt-10 pb-2 px-16 
  md:px-20 
  text-center 
  text-base 
  font-semibold 
  text-gray-600
}

.cf-tp-no-entries-body{
  @apply 
  pb-8 px-12 
  md:px-20 
  text-center 
  text-base 
  font-normal 
  text-gray-500
}







.text-page-subtitle-dark{
  @apply 
  text-base sm:text-lg
  font-semibold
  font-sans 
  text-gray-700
}


.layout-main{
  @apply
  bg-page-background max-w-lg min-h-screen overflow-x-hidden pt-20 sm:pt-44 pb-24 md:pb-8 px-5 md:px-12
}

.layout-clientview{
  @apply
  bg-gradient-to-r from-primary-200 to-secondary-200 max-w-lg min-h-screen pt-20 sm:pt-44 pb-24 md:pb-8
}

.card-journey{
  @apply
  bg-white rounded-lg border shadow-2xl shadow-secondary-400/15 py-4
}





.main-layout{
  @apply
  min-h-screen 
  overflow-x-hidden
  pt-24 sm:pt-48
  pb-24 md:pb-8 
  px-4 md:px-12
}
.main-layout-cf-tp{
  @apply
  min-h-screen 
  overflow-x-hidden
  pt-24 sm:pt-44
  pb-24 md:pb-8 
  px-4 md:px-12
}




/* Default style for font-normal */
.font-normal {
  font-weight: 400;
}

/* Safari-specific style */
@supports (-webkit-font-smoothing: antialiased) {
  .font-normal {
    font-weight: 450;
  }
}



.text-page-title-dark{
  @apply 
  text-xl md:text-xl 
  font-normal
  font-sans 
  text-gray-700
}

.text-body-cf-tp{
  @apply 
  text-base md:text-base 
  font-normal
  font-sans 
  text-gray-700
}

.text-body-cf-tp-semibold{
  @apply 
  text-base md:text-base 
  font-semibold
  font-sans 
  text-secondary-600
}



.text-dashboard-health-factor{
  @apply 
  text-base sm:text-base
  font-light
  text-gray-800
}
.text-dashboard-health-score{
  @apply 
  text-base sm:text-base
  font-semibold
  font-sans 
  text-gray-700
}




/* Body Text ---------------------------------------------------------------------------------------- */

/* Base -- */
.text-body-base-alpha{
  @apply 
  text-base md:text-sm
  font-normal  
  tracking-normal 
  leading-7
  font-sans 
}
.text-body-base{
  @apply 
  text-body-base-alpha
  text-gray-600
}
.text-body-base-white{
  @apply 
  text-body-base-alpha
  text-white
}

/* Small -- */
.text-body-sm-alpha{
  @apply 
  text-sm md:text-sm 
  font-normal 
  font-sans 
  tracking-normal 
  leading-normal
}
.text-body-sm{
  @apply 
  text-body-sm-alpha
  text-gray-600 
}
.text-body-sm-white{
  @apply 
  text-body-sm-alpha
  text-white
}

/* Display Text ---------------------------------------------------------------------------------------- */

/* Base -- */
.text-display-base-alpha{
  @apply  
  text-base sm:text-base
  font-medium
  font-sans 
}
.text-display-base{
  @apply  
  text-display-base-alpha
  text-gray-700
}
.text-display-base-white{
  @apply  
  text-display-base-alpha
  text-white
}

.text-display-base-alpha-thin{
  @apply  
  text-base sm:text-base 
  font-medium
  font-sans 
}
.text-display-base-thin{
  @apply  
  text-display-base-alpha-thin
  text-gray-600
}
.text-display-base-white-thin{
  @apply  
  text-display-base-alpha-thin
  text-white
}

/* Large -- */
.text-display-lg-alpha{
  @apply 
  text-lg sm:text-lg md:text-lg 
  font-semibold 
  font-sans 
}
.text-display-lg{
  @apply 
  text-display-lg-alpha
  text-gray-700
}
.text-display-lg-white{
  @apply 
  text-display-lg-alpha
  text-white
}

/* Small -- */
.text-display-sm-alpha{
  @apply 
  text-sm md:text-sm 
  font-semibold 
  leading-normal
}
.text-display-sm{
  @apply 
  text-display-sm-alpha
  text-gray-700
}
.text-display-sm-white{
  @apply 
  text-display-sm-alpha
  text-white 
}


/* CHAT PAGE ---------------------------------------------------------------------------------------- */

.chat-text{
  @apply 
  text-base md:text-base
  font-normal  
  tracking-normal 
  leading-normal
  font-sans 
  text-gray-800
}
.chat-layout{
  @apply
  bg-white 
  overflow-x-hidden
  mt-20 md:pb-8
  md:ml-32
}

/* HEADERS ---------------------------------------------------------------------------------------- */


.header-chat-container{
  @apply
  fixed top-0 left-0 right-0 z-30
  md:top-20 md:left-96
  md:px-12
  px-4
  md:pt-8
  border-b border-gray-200
  bg-page-background
}
.header-container{
  @apply
  h-20
  fixed top-0 left-0 right-0 z-30
  md:top-20 md:left-64
  md:px-0 md:mx-12
  px-4
  md:pt-4
  flex items-center justify-between 
  bg-page-background
}

.header-container-client-view{
  @apply
  h-20
  fixed top-0 left-0 right-0 z-30
  md:top-20 md:left-64
  md:px-0 md:mx-0
  px-4
  md:pt-4
  flex items-center justify-between 
  bg-page-background
  z-10
}

/* INTROCARD  -------------------------------------------------------------------------------------- */

.introcard-container{
  @apply 
  w-full max-w-md
  m-4 md:mt-56
  bg-white
  rounded-xl
  shadow-2xl
}
.introcard-header-container{
  @apply 
  w-full max-w-lg
  py-4 px-4 
  bg-gray-50
  rounded-lg
  overflow-hidden
}
.introcard-dismiss-button{
  @apply 
  absolute top-0 right-0 m-3 p-1.5 
  rounded-full 
  bg-primary-100 hover:bg-primary-300
  text-primary
}
.introcard-title-text{
  @apply 
  text-lg md:text-lg
  text-primary-600
  px-4
  font-semibold
  text-left 
  tracking-normal
  leading-7 
  font-sans
}
.introcard-body-text{
  @apply 
  text-base md:text-base
  px-4 pt-2
  text-gray-600
  font-normal 
  text-left 
  tracking-normal 
  leading-6 
  font-sans
}

/* ACTIVITIES ----------------------------------------------------------------------------- */

.text-activity-type{
  @apply 
  text-xs
  font-bold
  uppercase 
  tracking-wide
}
.text-activity-display{
  @apply 
  text-base sm:text-lg 
  font-normal
  text-white
}
.text-activity-display-card{
  @apply 
  text-base sm:text-base 
  font-normal
  text-white
}
.text-activity-start-display{
  @apply 
  font-medium text-lg md:text-lg text-white tracking-wide
}

.header-activity-container{
  @apply
  fixed top-0 left-0 right-0 z-30
  md:top-20 md:left-96
  max-w-lg
}
.text-activity-body{
  @apply 
  text-lg
  font-normal
  text-gray-600
  leading-7

}
.text-activity-icon-support{
  @apply 
  text-sm
  text-gray-700
  font-normal
}

.activity-icon{
  @apply 
  h-6 w-6 
  text-gray-700
}
.questionnaire-layout{
  @apply
  
  overflow-x-hidden
  pt-32 sm:pt-44
  pb-8
  md:ml-32
}
.reading-layout{
  @apply
  md:max-w-lg
  overflow-x-hidden
  pt-24 sm:pt-44
  pb-24 md:pb-8 
  md:ml-32
  bg-white
}
/* SPOTLIGHTS ----------------------------------------------------------------------------- */

.spotlight-card-text{
  @apply 
  text-base md:text-base 
  font-normal
  text-white
  leading-snug
  line-clamp-2
  
}
.spotlight-question-text{
  @apply
  text-lg 
  font-normal 
  text-white
  tracking-normal 
  leading-6
}
.spotlight-answer{
  @apply
  text-base 
  font-normal 
  text-gray-600  
  leading-normal
  focus:ring-transparent
}

/* ICONS ------------------------------------------------------------------------------------------------- */

.chevron-header-dark{
  @apply 
  h-7 w-7 
  text-gray-600
  stroke-2
}
.chevron-dark{
  @apply 
  h-7 w-7 
  text-gray-600 md:text-gray-400 
  stroke-2
}
.chevron-dashboard{
  @apply 
  h-6 w-6 
 
  stroke-1
}
.info-icon{
  @apply 
  h-6 w-auto md:h-6
  text-white 
  bg-gray-600 md:bg-gray-400 hover:text-gray-200 
  rounded-full
}
.filter{
  @apply 
  h-6 
  text-gray-700 md:text-gray-400 hover:text-gray-300
  stroke-2
}



/* PILLS -------------------------------------------------------------------------------------------------- */

.card-status{
  @apply
  absolute top-0 right-0 m-3 rounded-full
  px-2 py-0.5 
  bg-white
  text-xs 
  font-sans
  font-bold
  text-gray-700
}
.card-status-outline{
  @apply
  absolute top-0 right-0 m-3 rounded-full
  border border-gray-600
  px-2 py-0.5 
  bg-white
  text-xs 
  font-sans
  font-bold
  text-gray-700
}

/* modal closed layout -------------------------------------------------------------------------------------------------- */

.modal-closed-container{
  @apply 
  w-full md:max-w-sm
  py-2 sm:p-4 px-4 mx-auto
  border border-gray-200
  rounded-lg
  bg-white 
}
.modal-closed-container-sm{
  @apply 
  card-shadow
  w-28
  py-3 
  mx-auto 

  rounded-lg 
  bg-white
  
}
.modal-placement{
  @apply 
  min-h-full 
  flex items-center justify-center 
  p-4
}

/* modal open layout -------------------------------------------------------------------------------------------------- */

.modal-open-container{
  @apply 
  relative
  pb-4 sm:p-6
  w-full md:max-w-lg
  border border-black/10 
  rounded-lg 
  bg-white 
}
.modal-open-heading-container{
  @apply 
  py-2 mb-4
  bg-secondary-100
  rounded-sm
   
}
.modal-list{
  @apply 
  flex items-center 
  border-b border-gray-50 
  px-2 mx-2 py-3
}

.modal-dismiss-button{
  @apply 
  absolute top-0 right-0 m-3 p-1.5 
  rounded-full 
  bg-secondary-50 hover:bg-secondary-100
}
.modal-dismiss-icon{
  @apply 
  h-5 w-5
  text-secondary-600
}
.modal-icon{
  @apply 
  h-5
  pr-4 
  text-secondary-600
  stroke-2
  shrink-0
}
.modal-aim-number{
  @apply 
  h-7 w-7 
  p-1 mr-4 
  rounded-full
  bg-secondary-600
  text-sm
  font-medium
  text-white
  text-center
  font-sans
}
.modal-goal-number{
  @apply 
  h-7 w-7 
  p-1 mr-4 
  rounded-full
  bg-secondary-500
  text-sm
  font-medium
  text-white
  text-center
  font-sans
}
.modal-action-number{
  @apply 
  h-7 w-7 
  p-1 mr-4 
  rounded-full
  bg-secondary-300
  text-sm
  font-medium
  text-white
  text-center
  font-sans
}

/* BUTTONS ------------------------------------------------------------------------------------------------------- */

.button-primary{
  @apply 
  rounded-md 
  bg-primary hover:bg-primary-600
  px-4 py-2 
  text-base
  tracking-wide 
  font-semibold 
  text-white 
  text-center
  shadow-sm
  mx-auto 
}
.button-waiting-room{
  @apply 
  rounded-md 
  bg-secondary-500 hover:bg-secondary-600
  px-4 py-1
  text-base
  tracking-wide 
  font-semibold 
  text-white 
  text-center
  shadow-sm
  mx-auto 
}

.button-information-modal{
  @apply 
  rounded-md 
  bg-primary hover:bg-primary-600
  px-6 py-2 
  text-base
  tracking-wide 
  font-semibold 
  text-white 
  text-center
  shadow-sm
  mx-auto
  focus:ring-transparent
  outline-none
}

.button-secondary{
  @apply 
  rounded-full 
  bg-white hover:bg-gray-50
  px-4 py-2.5 
  text-sm 
  font-semibold 
  text-gray-900 
  shadow-sm 
  w-full
  ring-1 ring-inset ring-gray-500 
}
.button-activity{
  @apply 
  mt-12 mb-8 
  uppercase 
  w-full 
  block 
  mx-auto 
  rounded-md 
  hover:shadow-lg hover:opacity-80
  font-medium 
  text-white 
  px-6 py-2
  
}

.btn-ff-primary{
  @apply  
  uppercase 
  bg-primary-200
  border border-gray-700
  block 
  mx-auto 
  rounded-lg 
  hover:shadow-lg hover:opacity-80
  font-medium 
  text-gray-800 
  px-4 py-2
}

/* ALERTS --------------------------------------------------------------------------------------------------------- */

.alert-green{
  @apply 
  bg-green-200
  text-green-800 
  font-normal
  text-base
  p-3
  rounded-sm
}